.footer {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $secondary-container-light;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-sizing: border-box;

    .shortcuts-menu {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      background-color: $terciary-95-light;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .content {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      padding-top: 0;
      box-sizing: border-box;

      .version {
        font-size: 12px;
        color: gray;
      }
    }
  }

  span {
    @include property-responsive-md3(font-size, $label-medium);
    color: $on-surface-variant-light;
  }
}
