.dashboard-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-top: $navbar-height;
  background-color: $background-light;
  max-width: 100%;
  overflow: hidden;
  max-height: calc(100vh - $navbar-height);
}

.dashboard-content {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  max-width: 100%;
  overflow: hidden;
}

.dashboard-sidebar {
  display: flex;
  background-color: $surface-light;
  z-index: 0;
}

.dashboard-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  min-height: calc(100vh - $navbar-height);
}

.dashboard-body-content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-width: 100%;
}

.dashboard-screen {
  padding: 16px;

  h1 {
    font-size: $title-large;
    color: $secondary-light;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .item {
    width: 50%;

    @include large {
      width: 33%;
    }
  }
}
