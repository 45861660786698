.form-group {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;

  .form-control {
    flex: 1 1;
    margin-bottom: 20px;
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;

      &-space-around {
        justify-content: space-around;
        align-items: center;

        .form-input {
          flex: 1 1;
          justify-content: flex-end;
          &:last-child {
            justify-content: flex-start;
          }
        }
      }

      .avatar {
        width: 56px;
        height: 56px;
        margin-right: 10px;
      }
    }
    &-row:last-child {
      margin-bottom: 0;
    }

    .form-label {
      color: $error-light;
      font-size: 12px;
      margin-top: 4px;
    }
  }

  .form-input {
    display: flex;
    flex: 1 1;
  }

  .form-input-row {
    width: 49%;
  }

  .form-input-row-80 {
    width: 79%;
  }

  .form-input-row-20 {
    width: 19%;
  }

  .form-input-row-70 {
    width: 69%;
  }

  .form-input-row-30 {
    width: 29%;
  }

  .form-input-row-60 {
    width: 59%;
  }

  .form-input-row-40 {
    width: 39%;
  }

  .form-input-row-32 {
    width: 32%;
  }

  .list-item-field {
    margin: 0;
    padding: 0;
  }

  .list-item-field-primary-text {
    @include property-responsive-md3(font-size, $title-small);
    margin-right: 16px;
  }

  .list-item-field-secondary-text {
    @include property-responsive-md3(font-size, $label-small);
    color: $outline-light;
    margin-right: 16px;
  }

  .definition-form {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    max-width: 100%;
    padding: 24px 16px;

    .avatar {
      width: 84px;
      height: 84px;
      align-self: center;
      margin-bottom: 24px;
    }
  }

  .definition-form-row {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    padding: 24px 16px;

    .avatar {
      width: 84px;
      height: 84px;
      margin-right: 24px;
    }
  }

  .collapse-container {
    max-width: 100%;
  }

  .grow-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .avatar {
      width: 56px;
      height: 56px;
      margin-right: 10px;
      align-self: flex-start;
    }
  }

  .radio-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .radio-input {
    display: flex;
    flex: 1 1;
  }
}
