.advisor-account-label {
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 4px;

  @include large {
    margin-left: 8px;
  }

  .title {
    font-size: $body-small;
    color: gray;
  }
}
