$sidebar-width: 300px;

@mixin listItemContainer {
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  padding-left: 16px;
  padding-right: 24px;
  border-radius: 28px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;

  &:hover {
    background-color: $surface-variant;
  }
  &:active {
    background-color: $secondary-container-light;
  }

  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: transparent;

    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 1 1;
      background-color: transparent;

      .icon {
        width: 24px;
        height: 24px;
        color: $on-surface-variant-light;
        margin-right: 12px;
      }

      span {
        display: flex;
        flex: 1 1;
        text-decoration: none;
        color: $on-surface-variant-light;
        font-size: $title-medium;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background-color: transparent;
      .icon {
        width: 24px;
        height: 24px;
        color: $on-surface-variant-light;
      }
    }
  }
}

.siex-section {
  background-color: $siex-bg-light;
  border-radius: 28px;
}

.sidebar-temp {
  display: flex;
  width: $sidebar-width;
  max-width: 100vw;
  min-width: 250px;
  background-color: $background-light;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;

  @include large {
    display: none;
  }
}

.sidebar-temp-none {
  display: flex;
  @include large {
    display: none;
  }
}

.sidebar-perm {
  display: none;

  @include large {
    display: flex;
    width: $sidebar-width;
    max-width: 100vw;
    min-width: 250px;
    background-color: $background-light;
    z-index: 0;
  }
}

.drawer-content {
  padding: 12px;
  background-color: $background-light;
  .collapsable-list {
    display: flex;
    flex-direction: column;
  }

  .list-item-container {
    @include listItemContainer;
  }

  .list-item-container-active {
    @include listItemContainer;
    background-color: $secondary-container-light;
  }

  .list-item-container-disabled {
    @include listItemContainer;
    pointer-events: none;
  }

  .list-subitem-container {
    @include listItemContainer;
    padding-left: 36px;
  }

  .list-subitem-container-active {
    @include listItemContainer;
    padding-left: 36px;
    background-color: $secondary-container-light;
  }

  .list-subitem-container-disabled {
    @include listItemContainer;
    padding-left: 36px;
    pointer-events: none;
  }

  .account-sync-item-container {
    @include listItemContainer;
    background-color: $terciary-light;

    &:hover {
      background-color: $primary-light;
    }

    span {
      color: $background-light !important;
    }

    .icon {
      color: $background-light !important;
    }
  }

  .account-sync-item-container-active {
    @include listItemContainer;
    background-color: $primary-light;

    &:hover {
      background-color: $primary-light;
    }

    span {
      color: $background-light !important;
    }

    .icon {
      color: $background-light !important;
    }
  }
}
