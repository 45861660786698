@mixin iconActiveAnimation($name, $color1, $color2) {
  @keyframes #{$name} {
    0% {
      background-color: $color1;
      transform: scale(1);
    }
    50% {
      background-color: $color2;
      transform: scale(1.25);
    }
    100% {
      background-color: $color1;
      transform: scale(1);
    }
  }
}

.siex-status-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.siex-status-icon {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid;
}

.siex-status-icon-legend {
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 1.5px solid;
  margin-right: 6px;
}

.siex-status-icon-advisor_pending {
  background-color: $siex-advisor_pending-color-light;
  border-color: $siex-advisor_pending-color;

  &-active {
    @include iconActiveAnimation(
      iconActiveAnimationAdvisorPending,
      $siex-advisor_pending-color-light,
      $siex-advisor_pending-color
    );
    animation: iconActiveAnimationAdvisorPending 1.3s infinite;
  }
}
.siex-status-icon-pending {
  background-color: $siex-pending-color-light;
  border-color: $siex-pending-color;

  &-active {
    @include iconActiveAnimation(
      iconActiveAnimationPending,
      $siex-pending-color-light,
      $siex-pending-color
    );
    animation: iconActiveAnimationPending 1.3s infinite;
  }
}
.siex-status-icon-sent_pending {
  background-color: $siex-sent-pending-color-light;
  border-color: $siex-sent-pending-color;
  @include iconActiveAnimation(
    iconActiveAnimationSentPending,
    $siex-sent-pending-color-light,
    $siex-sent-pending-color
  );
  &-active {
    animation: iconActiveAnimationSentPending 1.3s infinite;
  }
}
.siex-status-icon-ok {
  background-color: $siex-ok-color-light;
  border-color: $siex-ok-color;

  &-active {
    @include iconActiveAnimation(
      iconActiveAnimationOk,
      $siex-ok-color-light,
      $siex-ok-color
    );
    animation: iconActiveAnimationOk 1.3s infinite;
  }
}
.siex-status-icon-nosync,
.siex-status-icon-error {
  background-color: $siex-error-color-light;
  border-color: $siex-error-color;

  &-active {
    @include iconActiveAnimation(
      iconActiveAnimationError,
      $siex-error-color-light,
      $siex-error-color
    );
    animation: iconActiveAnimationError 1.3s infinite;
  }
}

.popover-container {
  padding: 16px;

  .popover-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .popover-title {
    font-size: 18px;
    padding: 0;
    margin: 0;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .popover-date {
    font-size: 12px;
    padding: 0;
    margin: 0;
    color: gray;
  }

  .popover-legend-title {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  .popover-legend-subtitle {
    font-size: 11px;
    padding: 0;
    margin: 0;
    margin-top: 2px;
  }

  .popover-text__advisor_pending {
    color: $siex-advisor-pending-color;
  }
  .popover-text__pending {
    color: $siex-pending-color;
  }
  .popover-text__sent_pending {
    color: $siex-sent-pending-color;
  }
  .popover-text__ok {
    color: $siex-ok-color;
  }
  .popover-text__nosync,
  .popover-text__error {
    color: $siex-error-color;
  }

  h1 {
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
  }

  .status-cycle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    &-item_label-ok {
      font-size: 14px;
      padding: 2px 12px;
      color: $siex-ok-color;
    }
    &-item_line-ok {
      height: 8px;
      width: 100%;
      background-color: $siex-ok-color-light;
      box-sizing: border-box;
      border: 1px solid;
      border-color: $siex-ok-color;
    }

    &-item_label-error {
      font-size: 14px;
      padding: 2px 12px;
      color: $siex-error-color;
    }
    &-item_line-error {
      height: 8px;
      width: 100%;
      background-color: $siex-error-color-light;
      box-sizing: border-box;
      border: 1px solid;
      border-color: $siex-error-color;
    }
  }

  .popover-legend {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 12px;
  }

  .popover-legend-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;
  }

  .popover-legend-item-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
