.recipes-table {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    max-height: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 16px;
    }

    tr {
      border-bottom: 1px solid $outline-extra-light;
      max-width: 100%;
    }

    th {
      text-align: left;
      font-size: $title-medium;
      color: $outline-light;
      font-weight: bolder;
    }

    td {
      font-size: $title-medium;
      color: black;
      font-weight: bolder;
    }

    tbody tr td:last-child,
    th:last-child {
      position: sticky;
      right: 0;
      background-color: $background-light;
      z-index: 1;
    }

    /* TODO:    .sticky-column-shadow {
      transition: box-shadow 0.3s ease;
      box-shadow: -5px 0px 15px 0px rgba(0, 0, 0, 0.2);
      background-color: red;
    } */

    tbody tr:nth-child(even) td {
      background-color: $surface-light;
    }

    tbody tr:hover td {
      background-color: $surface-variant;
      cursor: pointer;
    }

    .selected td {
      background-color: $terciary-95-light !important;
    }

    .action-btns-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      button:first-child {
        animation: buttonPulse 1.5s infinite;

        &:hover {
          animation: none;
        }
      }

      @keyframes buttonPulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  .table-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
}

.recipes-view {
  max-width: 100%;

  @include large {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 24px;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid $outline-light;
  }

  th {
    text-align: left;
    font-size: $title-medium;
    color: $outline-light;
    font-weight: bolder;
  }

  td {
    font-size: $title-medium;
    color: black;
    font-weight: bolder;
  }

  tr td:first-child {
    text-align: left;
  }

  tr td:nth-child(2) {
    text-align: right;
  }

  tbody tr:nth-child(even) {
    background-color: $surface-light;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;

    @include large {
      //width: calc(100vw - $sidebar-width * 1.5);
      width: 40vw;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid $outline-light;
      background-color: $background-light;
      position: sticky;
      top: 0;
      z-index: 10;

      .header-titles {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow-x: hidden;
      }

      h1 {
        font-size: $title-large;
        color: black;
        padding: 0;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }

      h2 {
        font-size: $title-small;
        padding: 0;
        margin: 0;
        color: $outline-light;
        margin-top: 4px;
      }

      .header-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-left: 16px;
      }
    }

    .content-view {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 16px;
    }

    .section-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 16px;
      padding: 8px 0;
      border-bottom: 1px solid lightgray;
    }

    .section-header-right-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    h3 {
      display: flex;
      flex: 1;
      font-size: $headline-small;
      color: $primary-light;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    h4 {
      font-size: $title-medium;
      padding: 0;
      margin: 0;
      margin-bottom: 8px;
      color: $outline-light;
      font-weight: bolder;
    }

    p {
      font-size: $title-medium-large;
      color: black;
      padding: 0;
      margin: 0;
      font-weight: bolder;
    }

    .content-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 8px;
      animation: 0.3s slideDown 1;

      @keyframes slideDown {
        0% {
          opacity: 0;
          transform: translateY(-50px);
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      p {
        font-size: $title-large;
        color: black;
        padding: 0;
        margin: 0;
        font-weight: bolder;
      }

      a {
        text-decoration: none;
        font-size: $title-small;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        margin-left: 4px;
        color: $primary-light;

        &:hover {
          cursor: pointer;
        }
      }

      .large-text {
        font-size: $title-medium-large;
      }

      .success-text {
        color: $success-color;
      }
      .error-text {
        color: $error-light;
      }

      .content-row-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-height: 50px;
        margin-right: 16px;
        margin-bottom: 24px;

        .icon {
          color: $outline-light;
          font-size: 48px;
          margin-right: 6px;
        }

        .content-row-item-value {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }

      .content-row-item-full-width {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 24px;
      }
    }
  }

  .plant-problem {
    padding: 0;
    margin: 0;
    margin-top: 8px;

    .subheader {
      border-bottom: 1px solid $outline-extra-light;
      padding: 0;
      margin: 0;
    }

    .list-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 0;
      margin: 0;
      margin-top: 4px;

      .title-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        span {
          margin-right: 2px;
          vertical-align: top;
          max-width: 100%;
        }
      }

      span {
        margin-right: 2px;
        vertical-align: top;
        max-width: 80%;
      }

      .warning-text {
        padding: 0;
        margin-bottom: 4px;
        color: $warning-color;
        font-size: 12px;
      }

      .error-text {
        padding: 0;
        margin-bottom: 4px;
        color: $error-light;
        font-size: 12px;
      }
    }
  }
}

.recipes-product-popup {
  background-color: $background-light;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 8px rgba(0, 0, 0, 0.18);

  .filter-item {
    padding: 4px 16px;

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    &:hover {
      cursor: pointer;
      background-color: $surface-variant;
    }

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      span {
        margin: 0;
        padding: 0;
        margin-right: 2px;
        vertical-align: top;
      }
    }

    .warning-text {
      padding: 0;
      color: $warning-color;
      font-size: 12px;
    }
  }

  .not-results-text {
    padding: 16px;
    padding-top: 0;
    font-size: $body-medium;
    color: $outline-light;
  }
}

.recipe-product-details {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  @include medium {
    width: 40vw;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  @include large {
    width: 45vw;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $outline-light;
    background-color: $background-light;
    position: sticky;
    top: 0;
    z-index: 10;

    .header-titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    h1 {
      font-size: $title-large;
      color: black;
      padding: 0;
      margin: 0;
    }

    h2 {
      font-size: $title-small;
      padding: 0;
      margin: 0;
      color: $outline-light;
      margin-top: 4px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    .section-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid lightgray;
    }

    .filters-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 16px;
    }

    h3 {
      font-size: $headline-small;
      color: $primary-light;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    p {
      font-size: $title-medium-large;
      color: black;
      padding: 0;
      margin: 0;
      font-weight: bolder;
    }

    table {
      border-collapse: collapse;
      margin-bottom: 24px;
    }

    th,
    td {
      padding: 8px;
      border: 1px solid $outline-light;
    }

    th {
      text-align: left;
      font-size: $title-medium;
      color: $outline-light;
      font-weight: bolder;
    }

    td {
      font-size: $title-medium;
      color: black;
      font-weight: bolder;
    }

    tr td:first-child {
      text-align: left;
    }

    tr td:nth-child(2) {
      text-align: right;
    }

    tbody tr:nth-child(even) {
      background-color: $surface-light;
    }

    .success-text {
      color: $success-color;
    }
    .error-text {
      color: $error-light;
    }

    .content-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      .content-row-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 50px;
        margin-right: 16px;
        margin-bottom: 24px;

        p {
          font-size: $title-medium-large;
          color: black;
          padding: 0;
          margin: 0;
          font-weight: bolder;
        }

        .large-text {
          font-size: $title-medium;
        }

        .success-text {
          color: $success-color;
        }
        .error-text {
          color: $error-light;
        }
      }

      .table-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        overflow-x: scroll;
      }

      table {
        width: 100%;
      }

      th,
      td {
        width: 33%;
      }

      h4 {
        font-size: $title-medium;
        padding: 0;
        margin: 0;
        margin-bottom: 8px;
        color: $outline-light;
        font-weight: bolder;
      }
    }

    .use-container {
      box-sizing: border-box;
      border: 1px solid $outline-light;
      border-radius: 6px;
      margin-bottom: 8px;
      padding: 16px 8px;
      padding-bottom: 0;
      width: 100%;
    }
  }
}
