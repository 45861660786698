@mixin bannerIconAnimation($name, $color1, $color2) {
  @keyframes #{$name} {
    0% {
      background-color: $color1;
      transform: scale(1);
    }
    50% {
      background-color: $color2;
      transform: scale(1.25);
    }
    100% {
      background-color: $color1;
      transform: scale(1);
    }
  }
}

%siex-status-banner {
  padding: 12px;
  box-sizing: border-box;

  @include large {
    margin: 0 12px;
    border: 1px solid;
    border-radius: 16px;
  }
}

.siex-status-banner-advisor_pending {
  @extend %siex-status-banner;
  background-color: $siex-advisor-pending-color-light;
  border-color: $siex-advisor-pending-color-light;
}
.siex-status-banner-pending {
  @extend %siex-status-banner;
  background-color: $siex-pending-color-light;
  border-color: $siex-pending-color-light;
}
.siex-status-banner-sent_pending {
  @extend %siex-status-banner;
  background-color: $siex-sent-pending-color-light;
  border-color: $siex-sent-pending-color-light;
}
.siex-status-banner-ok {
  @extend %siex-status-banner;
  background-color: $siex-ok-color-light;
  border-color: $siex-ok-color-light;
}
.siex-status-banner-nosync,
.siex-status-banner-error {
  @extend %siex-status-banner;
  background-color: $siex-error-color-light;
  border-color: $siex-error-color-light;
}

.siex-error-reason-text {
  font-size: 14px;
  padding: 4px 0;
  margin: 0;
  color: darkred;
}

.siex-status-banner-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  p {
    font-size: 13px;
    padding: 0;
    margin: 0;
    color: darkslategray;
  }
}

.siex-status-banner-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

%siex-status-banner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid;
  margin-right: 12px;
}

.siex-status-banner-icon-advisor_pending {
  @extend %siex-status-banner-icon;
  border-color: $siex-advisor-pending-color;

  @include iconActiveAnimation(
    bannerIconAnimationAdvisorPending,
    $siex-advisor-pending-color-light,
    $siex-advisor-pending-color
  );
  animation: bannerIconAnimationAdvisorPending 1.3s infinite;
}
.siex-status-banner-icon-pending {
  @extend %siex-status-banner-icon;
  border-color: $siex-pending-color;

  @include iconActiveAnimation(
    bannerIconAnimationPending,
    $siex-pending-color-light,
    $siex-pending-color
  );
  animation: bannerIconAnimationPending 1.3s infinite;
}
.siex-status-banner-icon-sent_pending {
  @extend %siex-status-banner-icon;
  border-color: $siex-sent-pending-color;

  @include iconActiveAnimation(
    bannerIconAnimationSentPending,
    $siex-sent-pending-color-light,
    $siex-sent-pending-color
  );
  animation: bannerIconAnimationSentPending 1.3s infinite;
}
.siex-status-banner-icon-ok {
  @extend %siex-status-banner-icon;
  border-color: $siex-ok-color;

  @include iconActiveAnimation(
    bannerIconAnimationOk,
    $siex-ok-color-light,
    $siex-ok-color
  );
  animation: bannerIconAnimationOk 1.3s infinite;
}
.siex-status-banner-icon-nosync,
.siex-status-banner-icon-error {
  @extend %siex-status-banner-icon;
  border-color: $siex-error-color;

  @include iconActiveAnimation(
    bannerIconAnimationError,
    $siex-error-color-light,
    $siex-error-color
  );
  animation: bannerIconAnimationError 1.3s infinite;
}

.siex-status-banner-title {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.siex-status-banner-date {
  font-size: 12px;
  padding: 0;
  margin: 0;
  color: gray;
}

.siex-status-banner-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    font-size: 14px;
    padding: 0;
    margin: 4px 0;
  }
}
