.header-image {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: $outline-extra-light;
  padding: 56px;

  .image-container {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .image-icon {
    width: 56px;
    height: 56px;
    color: $outline-light;
  }
}
