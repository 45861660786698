.vademecum-phyto-table {
  display: flex;
  flex-direction: column;
  background-color: $background-light;
  max-width: 100%;
  box-sizing: border-box;

  @include large {
    border-radius: 16px;
    margin: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
    border: 1px solid $outline-extra-light;
  }

  .header {
    display: flex;
    flex-direction: row;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    background-color: $secondary-container-light;

    @include large {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }

    h1 {
      @include property-responsive-md3(font-size, $title-medium);
      color: black;
      font-weight: bold;
      margin-right: 4px;
      @include large {
        font-size: $title-medium-large;
      }
    }
  }

  .content {
    min-width: 100%;
    overflow-x: auto;
    margin: 0;
    padding: 0;

    .content-table-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 12px 0;

      .table-filter {
        padding: 12px 8px;
        padding-right: 0;

        .filter-text-field {
          width: 150px;

          @include large {
            width: 200px;
          }
        }
      }
    }

    .table-container {
      max-height: 100%;

      .table-row:nth-child(even) {
        background-color: $surface-light;
      }

      .table-row:hover {
        background-color: $surface-variant;
        cursor: pointer;
      }

      .table-cell-head {
        padding: auto;
        font-weight: bold;
        font-size: $body-medium;
        border-top: 2px solid $outline-extra-light;
        border-bottom: 2px solid $outline-extra-light;

        @include large {
          font-size: $body-medium-large;
        }
      }

      .table-cell {
        padding: auto;
        font-size: $body-medium;

        @include large {
          font-size: $body-medium-large;
        }
      }
    }

    .table-pagination {
      max-width: 100%;
    }
  }
}

.vademecum-phyto-drawer {
  max-width: 100%;

  @include large {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid $outline-light;
      background-color: $background-light;
      position: sticky;
      top: 0;
      z-index: 10;

      .header-titles {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      h1 {
        font-size: $title-large;
        color: black;
        padding: 0;
        margin: 0;
      }

      h2 {
        font-size: $title-small;
        padding: 0;
        margin: 0;
        color: $outline-light;
        margin-top: 4px;
      }

      .header-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 16px;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      .section-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid lightgray;
      }

      .filters-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 16px;
      }

      h3 {
        font-size: $headline-small;
        color: $primary-light;
        padding: 0;
        margin: 0;
        width: 100%;
      }

      p {
        font-size: $title-medium-large;
        color: black;
        padding: 0;
        margin: 0;
        font-weight: bolder;
      }

      table {
        border-collapse: collapse;
        margin-bottom: 24px;
      }

      th,
      td {
        padding: 8px;
        border: 1px solid $outline-light;
      }

      th {
        text-align: left;
        font-size: $title-medium;
        color: $outline-light;
        font-weight: bolder;
      }

      td {
        font-size: $title-medium;
        color: black;
        font-weight: bolder;
      }

      tr td:first-child {
        text-align: left;
      }

      tr td:nth-child(2) {
        text-align: right;
      }

      tbody tr:nth-child(even) {
        background-color: $surface-light;
      }

      .success-text {
        color: $success-color;
      }
      .error-text {
        color: $error-light;
      }

      .content-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .content-row-item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          min-height: 50px;
          margin-right: 16px;
          margin-bottom: 24px;

          p {
            font-size: $title-medium-large;
            color: black;
            padding: 0;
            margin: 0;
            font-weight: bolder;
          }

          .large-text {
            font-size: $title-medium;
          }

          .success-text {
            color: $success-color;
          }
          .error-text {
            color: $error-light;
          }
        }

        .table-section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          overflow-x: scroll;
        }

        table {
          width: 100%;
        }

        th,
        td {
          width: 33%;
        }

        h4 {
          font-size: $title-medium;
          padding: 0;
          margin: 0;
          margin-bottom: 8px;
          color: $outline-light;
          font-weight: bolder;
        }
      }

      .use-container {
        box-sizing: border-box;
        border: 1px solid $outline-light;
        border-radius: 6px;
        margin-bottom: 8px;
        padding: 16px 8px;
        padding-bottom: 0;
        width: 100%;
      }
    }

    @include large {
      width: calc(100vw - $sidebar-width * 1.5);
    }
  }
}

.crop-filter-popup {
  background-color: $background-light;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 8px rgba(0, 0, 0, 0.18);

  .filter-item {
    padding: 4px 16px;

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    &:hover {
      cursor: pointer;
      background-color: $surface-variant;
    }

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      span {
        margin: 0;
        padding: 0;
        margin-right: 2px;
        vertical-align: top;
      }
    }

    .warning-text {
      padding: 0;
      color: $warning-color;
      font-size: 12px;
    }
  }

  .not-results-text {
    padding: 16px;
    padding-top: 0;
    font-size: $body-medium;
    color: $outline-light;
  }
}

.vademecum-fertiliz-table {
  display: flex;
  flex-direction: column;
  background-color: $background-light;
  max-width: 100%;
  box-sizing: border-box;

  @include large {
    border-radius: 16px;
    margin: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
    border: 1px solid $outline-extra-light;
  }

  .header {
    display: flex;
    flex-direction: row;
    height: 36px;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    background-color: $secondary-container-light;

    @include large {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }

    h1 {
      @include property-responsive-md3(font-size, $title-medium);
      color: black;
      font-weight: bold;
      margin-right: 4px;
      @include large {
        font-size: $title-medium-large;
      }
    }
  }

  .content {
    min-width: 100%;
    overflow-x: auto;
    margin: 0;
    padding: 0;

    .content-table-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 12px 0;

      .table-filter {
        padding: 12px 8px;
        padding-right: 0;

        .filter-text-field {
          width: 150px;

          @include large {
            width: 200px;
          }
        }
      }
    }

    .table-container {
      max-height: 100%;

      .table-row:nth-child(even) {
        background-color: $surface-light;
      }

      .table-row:hover {
        background-color: $surface-variant;
        cursor: pointer;
      }

      .table-cell-head {
        padding: auto;
        font-weight: bold;
        font-size: $body-medium;
        border-top: 2px solid $outline-extra-light;
        border-bottom: 2px solid $outline-extra-light;

        @include large {
          font-size: $body-medium-large;
        }
      }

      .table-cell {
        padding: auto;
        font-size: $body-medium;

        @include large {
          font-size: $body-medium-large;
        }
      }
    }

    .table-pagination {
      max-width: 100%;
    }
  }
}

.vademecum-fertiliz-drawer {
  max-width: 100%;

  @include large {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid $outline-light;
      background-color: $background-light;
      position: sticky;
      top: 0;
      z-index: 10;

      .header-titles {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      h1 {
        font-size: $title-large;
        color: black;
        padding: 0;
        margin: 0;
      }

      h2 {
        font-size: $title-small;
        padding: 0;
        margin: 0;
        color: $outline-light;
        margin-top: 4px;
      }

      .header-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 16px;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      .section-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid lightgray;
      }

      .filters-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 16px;
      }

      h3 {
        font-size: $headline-small;
        color: $primary-light;
        padding: 0;
        margin: 0;
        width: 100%;
      }

      p {
        font-size: $title-medium-large;
        color: black;
        padding: 0;
        margin: 0;
        font-weight: bolder;
      }

      table {
        border-collapse: collapse;
        margin-bottom: 24px;
      }

      th,
      td {
        padding: 8px;
        border: 1px solid $outline-light;
      }

      th {
        text-align: left;
        font-size: $title-medium;
        color: $outline-light;
        font-weight: bolder;
      }

      td {
        font-size: $title-medium;
        color: black;
        font-weight: bolder;
      }

      tr td:first-child {
        text-align: left;
      }

      tr td:nth-child(2) {
        text-align: right;
      }

      tbody tr:nth-child(even) {
        background-color: $surface-light;
      }

      .success-text {
        color: $success-color;
      }
      .error-text {
        color: $error-light;
      }

      .content-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .content-row-item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          min-height: 50px;
          margin-right: 16px;
          margin-bottom: 24px;

          p {
            font-size: $title-medium-large;
            color: black;
            padding: 0;
            margin: 0;
            font-weight: bolder;
          }

          .large-text {
            font-size: $title-medium;
          }

          .success-text {
            color: $success-color;
          }
          .error-text {
            color: $error-light;
          }
        }

        .table-section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          overflow-x: scroll;
        }

        table {
          width: 100%;
        }

        th,
        td {
          width: 33%;
        }

        h4 {
          font-size: $title-medium;
          padding: 0;
          margin: 0;
          margin-bottom: 8px;
          color: $outline-light;
          font-weight: bolder;
        }
      }

      .use-container {
        box-sizing: border-box;
        border: 1px solid $outline-light;
        border-radius: 6px;
        margin-bottom: 8px;
        padding: 16px 8px;
        padding-bottom: 0;
        width: 100%;
      }
    }

    @include large {
      width: calc(100vw - $sidebar-width * 1.5);
    }
  }
}
