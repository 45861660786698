.onBoarding {
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    height: var(--doc-height);
    width: 100vw;

    .header {
      width: calc(100% - 48px);

      .logo {
        display: flex;
        height: 40px;
        background-image: url("../../assets/logo_full.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 24px;
        margin-bottom: 32px;

        @include medium {
          height: 50px;
        }
        @include large {
          height: 50px;
          background-position: left;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 24px;
      height: 100%;
      width: calc(100% - 48px);

      @include medium {
        max-width: 800px;
      }
      @include large {
        max-width: 800px;
      }

      span {
        font-size: 16px;
        margin-bottom: 8px;
        text-align: left;
      }

      .step-btns {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
      }

      .form-section {
        width: 100%;

        .form-header {
          margin-bottom: 24px;
        }

        h3 {
          font-size: $title-large;
          color: black;
          margin: 0;
          margin-bottom: 12px;
        }

        a {
          text-decoration: none;
          color: gray;
        }
      }
    }
  }
}
