html,
body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100vw;
  height: 100vh;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0;
  padding: 0;
  zoom: 1;
}

// Responsive mixins
// Material Design breakpoints
@mixin small {
  @media only screen and (min-width: 0px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 840px) {
    @content;
  }
}

/* Define a mixin for responsive font sizes following Material Design 3 guidelines */
@mixin property-responsive-md3($property, $base-value) {
  #{$property}: $base-value;
  @include medium {
    #{$property}: $base-value * 1.1; /* Increase font size by 25% for medium screens */
  }
  @include large {
    #{$property}: $base-value * 1.25; /* Increase font size by 56.25% (4/5) for large screens */
  }
}

$elevation1: 0 2px 4px rgba(0, 0, 0, 0.2);

.hide {
  display: none;
}
