.shortcut-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-light;
  border-radius: 16px;
  margin: 12px;
  padding: 24px;
  border: 1px solid $secondary-light;
  height: 125px;
  overflow: hidden;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    background-color: $terciary-90-light;
  }

  .icon {
    color: $secondary-light;
  }

  span {
    font-size: $body-medium;
    text-align: center;
    margin-top: 8px;

    @include medium {
      font-size: $body-medium-large;
    }

    @include large {
      font-size: $body-large;
    }
  }
}
