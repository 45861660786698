.offer {
  .container {
    display: flex;
    flex-direction: column;
    background-color: $background-light;
    max-width: 100%;
    box-sizing: border-box;
    margin: 12px;

    .messageLabel {
      text-align: left;
      font-size: $title-medium;
    }

    .messageContent {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      margin-bottom: 16px;
      margin-top: 8px;
      border: 1px solid $outline-extra-light;
      padding: 8px;
      padding-inline: 16px;

      .messageDate {
        text-align: right;
        width: 100%;
        font-size: $body-small;
        color: gray;
      }
    }
  }
}
