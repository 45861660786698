.onboarding-siex-banner {
  background-color: $siex-bg-light;
  border: 1px solid $siex-bg-light;
  border-radius: 16px;
  margin: 16px 0 24px 0;
  box-sizing: border-box;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
  }

  .icon {
    width: 36px;
    height: 36px;
    color: black;
    margin-right: 8px;
  }

  h1 {
    font-size: 16px;
    padding: 0;
    margin: 0;

    @include large {
      font-size: 18px;
    }
  }

  p {
    font-size: 14px;
    color: black;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;

    @include large {
      font-size: 16px;
    }
  }

  .btn {
    align-self: center;
    margin-top: 8px;
  }
}

.onboarding-siex-banner__requested {
  background-color: $tertiary-container-light;
  border: 1px solid $tertiary-container-light;
  animation: subscribeRequestedAnimation 2s;

  @keyframes subscribeRequestedAnimation {
    0% {
      background-color: $siex-bg-light;
    }
    100% {
      background-color: $tertiary-container-light;
    }
  }

  .icon {
    width: 36px;
    height: 36px;
    color: black;
    margin-right: 8px;
    animation: siexIconAnimation 1s;

    @keyframes siexIconAnimation {
      0% {
        transform: scale(1);
        color: black;
      }
      50% {
        transform: scale(1.3);
        color: $success-color;
      }
      100% {
        transform: scale(1);
        color: black;
      }
    }
  }
}
