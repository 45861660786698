.siex-banner {
  background-color: $siex-bg-light;
  border: 1px solid $siex-bg-light;
  border-radius: 16px;
  margin: 12px;
  box-sizing: border-box;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;

    .icon {
      width: 24px;
      height: 24px;
      color: $on-surface-variant-light;
      margin-right: 8px;
    }

    h1 {
      font-size: $label-large;
      color: black;
      margin: 0;
      padding: 0;
    }

    h2 {
      font-size: $body-small;
      color: black;
      margin: 0;
      padding: 0;
      margin-top: 4px;
      line-height: 18px;
    }

    h3 {
      font-size: $body-small;
      color: gray;
      margin: 0;
      padding: 0;
      margin-top: 6px;
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;

      .btn {
        line-height: normal;
      }
    }
  }
}

.siex-banner__requested {
  background-color: $tertiary-container-light;
  border: 1px solid $tertiary-container-light;
}

.icon__requested {
  width: 24px;
  height: 24px;
  color: $on-surface-variant-light;
  margin-right: 8px;
  animation: siexIconAnimation 1s;

  @keyframes siexIconAnimation {
    0% {
      transform: scale(1);
      color: black;
    }
    50% {
      transform: scale(1.3);
      color: $success-color;
    }
    100% {
      transform: scale(1);
      color: black;
    }
  }
}
