.right-drawer {
  max-width: 100%;
  @include medium {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  @include large {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .tabbar {
    background-color: $background-light;
    top: 84px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;

    .appbar {
      background-color: $background-light;
      border-bottom: 1px solid $outline-light;
      height: 84px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      padding-left: 0;

      &-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 1 1;

        .header-texts {
          h1 {
            font-size: $title-medium-large;
            color: black;
            padding: 0;
            margin: 0;
          }

          h2 {
            font-size: $label-medium;
            color: $outline-light;
            padding: 0;
            margin: 0;
            margin-top: 4px;
          }
        }
      }

      &-right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .children {
      @include large {
        margin-top: 8px;
      }
    }

    @include medium {
      width: 40vw;
    }

    @include large {
      width: 40vw;
    }
  }
}
