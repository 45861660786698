$primary-light: #3b6a00;
$secondary-light: #57624a;
$terciary-light: #386664;
$secondary-container-light: #dbe7c8;
$secondary-95-light: #e9f5d6;
$on-surface-variant-light: #44483d;
$surface-light: #ebf1e4;
$background-light: white;
$on-secondary-container-light: #151e0b;
$tertiary-container-light: #bbece8;
$terciary-95-light: #c9faf7;
$terciary-90-light: #bbece8;
$outline-light: #74796c;
$outline-extra-light: lightgray;
$surface-variant: #e1e4d5;
$surface-variant-95: #eff2e3;
$surface-variant-99: #f8ffe9;
$error-light: #c62828;
$siex-bg-light: #e0e0e0;
$success-color: #5cb106;
$info-color: #0288d1;
$warning-color: #ed6c02;
$siex-advisor-pending-color: gray;
$siex-advisor-pending-color-light: rgba(128, 128, 128, 0.3);
$siex-pending-color: rgb(219, 147, 13);
$siex-pending-color-light: rgba(219, 147, 13, 0.3);
$siex-ok-color: green;
$siex-ok-color-light: rgba(0, 255, 0, 0.5);
$siex-sent-pending-color: rgb(0, 128, 255);
$siex-sent-pending-color-light: rgba(0, 128, 255, 0.2);
$siex-error-color: red;
$siex-error-color-light: rgba(255, 0, 0, 0.5);
